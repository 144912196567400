<template>
  <div style="display: flex; align-items: center; height: 75vh; justify-content: center">
    <div>
      <div class="mb-4">
        <h2 class="text-black ghd-ITCAvant text-center">
          {{ getSubHeaderText() }}
        </h2>
      </div>
      <v-card
        class="ghd-border-black"
        style="max-width: 480px; margin: auto; flex: 1"
      >
        <v-form
          ref="searchOrderForm"
          class="pb-6 pt-2 pl-2 pr-2 multi-col-validation"
        >
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="sendData.seriale"
                label="Numero seriale (Date code)"
                outlined
                dense
                hide-details="auto"
                type="text"
                :rules="[validators.required]"
                placeholder="Numero seriale (Date code)"
                class="pt-3"
                :append-icon="icons.mdiHelpCircle"
                @click:append="openWhereIsDataCodeDialog()"
                @keydown.enter="onSubmit()"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="12"
              class="text-center"
            >
              <v-btn
                color="primary"
                block
                :disabled="isSearchingItem"
                @click="onSubmit"
              >
                Ricerca
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>

      <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
        <p class="mb-0 me-2 text-black ghd-helveticanew">
          Non hai il codice seriale?
        </p>
        <span
          class="text-decoration-underline text-gold cursor-pointer ghd-helveticanew"
          @click="searchWithoutSerial()"
        >APRI ASSISTENZA</span>
      </v-card-text>
    </div>
    <dialog-where-is-data-code
      v-if="isDialogWhereisDataCodeVisible"
      @turnoff="isDialogWhereisDataCodeVisible = false"
    />
  </div>
</template>

<script>

import { ref } from '@vue/composition-api'
import {
  integerValidator,
  alphaDashValidator,
  required,
  lengthValidator,
} from '@core/utils/validation'
import { mdiHelpCircle } from '@mdi/js'
import router from '@/router'
import assistanceService from '@/services/assistanceService'
import DialogWhereIsDataCode from '@/views/dialogs/dialogWhereIsDataCode.vue'
import store from '@/store'

export default {
  components: { DialogWhereIsDataCode },
  setup() {
    const searchOrderForm = ref(null)

    // Form Submitted Snackbar

    const isDialogWhereisDataCodeVisible = ref(false)
    const isSearchingItem = ref(false)

    // const { isDark } = useAppConfig()
    // isDark.value = false
    const blankSendData = {
      seriale: null,
    }

    const sendData = ref(JSON.parse(JSON.stringify(blankSendData)))
    const resetSendData = () => {
      sendData.value = JSON.parse(JSON.stringify(blankSendData))
    }

    const searchWithoutSerial = () => {
      store.commit('assistance/setSearchedOrder', 'empty')
      router.push({ name: 'assistenza-create' })
    }

    const onSubmit = () => {
      if (searchOrderForm.value.validate()) {
        isSearchingItem.value = true
        store.dispatch('assistance/fetchOrder', sendData.value)
          .then(() => {
            isSearchingItem.value = false
          }).catch(() => {
          }).finally(() => {
            setTimeout(() => {
              router.push({ name: 'assistenza-create' })
            }, 1500)
          })
      }
    }

    const {
      getSubHeaderText,
    } = assistanceService()

    const openWhereIsDataCodeDialog = () => {
      isDialogWhereisDataCodeVisible.value = true
    }

    return {
      searchOrderForm,
      isDialogWhereisDataCodeVisible,
      sendData,
      isSearchingItem,

      resetSendData,
      searchWithoutSerial,
      onSubmit,
      getSubHeaderText,
      openWhereIsDataCodeDialog,
      icons: {
        mdiHelpCircle,
      },

      // Field Validators
      validators: {
        required,
        alphaDashValidator,
        integerValidator,
        lengthValidator,
      },
    }
  },
}
</script>

<style lang='scss' scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
